var fixedNav = (function () {
    return{
        init: function () {
            if(document.querySelector('html').offsetWidth > 800){
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 370){
                        $('.nav').css({
                            'position':'fixed',
                            'top': '0',
                            'z-index': '100000',
                            'right': '0',
                            'left': '0'
                        });
                        $('.nav__list').css({
                            'justify-content': 'center'
                        });
                    } else {
                        $('.nav').css({
                            'position':'static'
                        });
                        $('.nav').fadeIn();
                        $('.nav__list').css({
                            'justify-content': 'flex-start'
                        });
                    }
                });
            }
        }
    }
})();