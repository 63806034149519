var callForm = function () {
    return {
        init: function () {
            var callBtn = $('.callBtn'),
                form = $('.call-form__bg'),
                canselBtn = $('.cancelBtn');
            callBtn.on('click',function (e) {
                e.preventDefault();
                form.css({
                    'display': 'flex'
                })
            });
            canselBtn.on('click',function () {
                form.css({
                    'display': 'none'
                })
            })

        }
    }
}();