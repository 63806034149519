
    $(document).ready(function(){

        $('.hidden_field').parent().hide();
        var text_res = $('#calc-result').html();
        $('#get_result').click(function(){

            var sure_field_count = 0;

            $('.sure_field').each(function(){
                if( $(this).val() == '' ) sure_field_count++;
            });
            if( sure_field_count == 0 ){
                eval(" result_proc =parseFloat($('#id_1 option:selected').val())*parseFloat($('#id_2 input').val())");
                if( isNaN(result_proc) ) {$('#calc-result').html('Поля заполнены неверно!');
                setTimeout(function () {
                    $('#calc-result').html(text_res);
                },1000)
                }
                else $('#view_result').html(result_proc.toFixed(1));
            }
            else $('#view_result').html('Не заполнены обязательные поля!');
        });

    });

